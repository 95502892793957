import { useState, useEffect } from "react";
import styled from "styled-components";
import SectionBackground from "./../assets/section-background.svg";
import StockimageDoor from "./../assets/stockimage-door.jpg";
import GalleryImage1 from "./../assets/gallery/gallery-image-1.jpg";
import GalleryImage2 from "./../assets/gallery/gallery-image-2.jpg";
import GalleryImage3 from "./../assets/gallery/gallery-image-3.jpg";
import GalleryImage4 from "./../assets/gallery/gallery-image-4.jpg";
import GalleryImage5 from "./../assets/gallery/gallery-image-5.jpg";
import GalleryImage6 from "./../assets/gallery/gallery-image-6.jpg";
import GalleryImage7 from "./../assets/gallery/gallery-image-7.jpg";
import GalleryImage8 from "./../assets/gallery/gallery-image-8.jpg";
import GalleryImage9 from "./../assets/gallery/gallery-image-9.jpg";
import GalleryImage10 from "./../assets/gallery/gallery-image-10.jpg";
import GalleryImage11 from "./../assets/gallery/gallery-image-11.jpg";
import GalleryImage12 from "./../assets/gallery/gallery-image-12.jpg";
import GalleryImage13 from "./../assets/gallery/gallery-image-13.jpg";
import GalleryImage14 from "./../assets/gallery/gallery-image-14.jpg";
import Theme from "../Theme";
import { transparentize, darken } from "polished";

function Gallery() {
  const images = [
    { src: StockimageDoor, show: true, alt: "Edle Innentür in weiß" },
    { src: GalleryImage10, show: true, alt: "Sektionaltor grau" },
    { src: GalleryImage11, show: true, alt: "Sektionaltor grau" },
    { src: GalleryImage12, show: true, alt: "Sektionaltor weiß" },
    { src: GalleryImage13, show: true, alt: "Sektionaltor grau" },
    { src: GalleryImage14, show: true, alt: "Sektionaltor grau" },
    { src: GalleryImage1, show: true, alt: "Garagentor in Kalkar" },
    { src: GalleryImage2, show: true, alt: "Garagentor in Kleve" },
    { src: GalleryImage3, show: true, alt: "Garagentor in Goch" },
    { src: GalleryImage4, show: true, alt: "Garagentor in Emmerich" },
    { src: GalleryImage5, show: false, alt: "Garagentor in Rees" },
    { src: GalleryImage6, show: true, alt: "Garagentor in Xanten" },
    { src: GalleryImage7, show: true, alt: "Garagentor in Rees" },
    { src: GalleryImage8, show: true, alt: "Garagentür in Uedem" },
    { src: GalleryImage9, show: true, alt: "Antrieb in Hasselt" },
  ].filter((img) => img.show);

  const [image, setImage] = useState<number>(0);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setImage((image + 1) % (images.length - 1));
    }, 5000);
    return () => window.clearTimeout(timer);
  }, [image, images.length]);

  const previous = (image + images.length - 2) % (images.length - 1);
  const current = image;
  const next = (image + 1) % (images.length - 1);

  return (
    <DetailSection>
      <Header>Unsere Referenzen</Header>
      <SubHeader>
        In unserer Galerie finden Sie ausgewählte Bilder unserer Arbeit
      </SubHeader>

      <>
        <Image
          key={previous}
          src={images[previous].src}
          alt={images[previous].alt}
          visible={false}
        />
        <Image
          key={current}
          src={images[current].src}
          alt={images[current].src}
          visible={true}
          onClick={() => setImage(next)}
        />
        <Image
          key={next}
          src={images[next].src}
          alt={images[next].src}
          visible={false}
        />
      </>
    </DetailSection>
  );
}

export default Gallery;

const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(${SectionBackground});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 14rem 2rem 20rem 2rem;
  color: ${Theme.colors.light};
  border: none;
  position: relative;
`;

const Header = styled.h2`
  flex: 0 1 min-content;
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 2rem;
  margin: 0 auto;
`;

const SubHeader = styled.h3`
  font-weight: 400;
`;

const Image = styled.img<{ visible: boolean }>`
  flex: 0 1 min-content;
  height: 24rem;
  position: absolute;
  bottom: -12rem;
  box-shadow: 0 10px 32px 0
    ${transparentize(0.91, darken(0.5, Theme.colors.primary))};
  box-sizing: content-box;
  max-width: 100%;
  transition: transform 0.3s ease-in, opacity 1.2s ease-in-out,
    z-index 0.6s ease-in-out 0.6s;
  opacity: ${(p) => (p.visible ? 1 : 0)};
  z-index: ${(p) => (p.visible ? 2 : 1)};
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 11px 33px 0
      ${transparentize(0.88, darken(0.5, Theme.colors.primary))};
  }
`;
