import styled from "styled-components";
import HeroMask from "./../assets/hero-mask.svg";
import Theme from "../Theme";
import IconScrollDown from "./svg/IconScrollDown";
import UserEvent from "../analytics/UserEvent";
import { FadeInBottom } from "./animations/FadeInBottom";
import { lighten } from "polished";
import StockHeroImageJpg from "./../assets/stockimage-hero.jpg";
import StockHeroImageWebP from "./../assets/stockimage-hero.webp";
import StockHeroImageAvif from "./../assets/stockimage-hero.avif";

function Hero() {
  return (
    <HeroSection>
      <HeroImage />
      <HeroColorOverlay />
      <StyledHeroMask />
      <HeroHeader>Hubert Handwerk</HeroHeader>
      <HeroSubHeader>Ihr Partner für Türen und Tore</HeroSubHeader>
      <HeroDescription>
        Als familiengeführtes Handwerksunternehmen aus Kalkar überzeugen wir
        gerne mit hervorragenden Leistungen, maßgeschneiderten Lösungen und
        bester Qualität.
      </HeroDescription>
      <CallToActionContainer>
        <PrimaryCallToAction
          href="tel:+4915750992638"
          onClick={() => UserEvent("Call", "Anruf über Primäraktion")}
        >
          Jetzt anrufen
        </PrimaryCallToAction>
        <SecondaryCallToAction
          href="https://www.instagram.com/huberthandwerk/"
          onClick={() =>
            UserEvent("Instagram", "Instagram über Sekundäraktion")
          }
        >
          Instagram
        </SecondaryCallToAction>
      </CallToActionContainer>
      <ScrollDownIconLink href="#service-section">
        <StyledScrollDownIcon />
      </ScrollDownIconLink>
    </HeroSection>
  );
}

export default Hero;

const HeroImage = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100vw;
  background-image: url(${StockHeroImageJpg});
  background-image: image-set(
    url(${StockHeroImageAvif}) type("image/avif"),
    url(${StockHeroImageWebP}) type("image/webp"),
    url(${StockHeroImageJpg}) type("image/jpeg")
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.8;
  z-index: -3;
`;

const HeroColorOverlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100vw;
  background-color: ${Theme.colors.primary};
  opacity: 0.8;
  z-index: -2;
`;

const StyledHeroMask = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  color: #fff;
  background-image: url(${HeroMask});
`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  color: ${Theme.colors.light};
  padding: 7rem 1rem 7rem 1rem;
  box-sizing: border-box;
`;

const HeroHeader = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  padding: 0rem 2rem 2rem 2rem;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }

  ${FadeInBottom}
  @media screen and (max-height: 600px) {
    display: none;
  }
`;

const HeroSubHeader = styled.h2`
  font-size: 1.8rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }

  ${FadeInBottom}
  animation-delay: .5s;
  @media screen and (max-height: 700px) {
    animation-delay: 0s;
  }
`;

const HeroDescription = styled.div`
  font-size: 1.2rem;
  padding: 0 6rem 4rem 6rem;
  line-height: 2.4rem;

  @media screen and (max-width: 450px) {
    padding: 0 2rem 3rem 2rem;
    font-size: 1rem;
  }

  ${FadeInBottom}
  animation-delay: 1s;
  @media screen and (max-height: 700px) {
    animation-delay: 0.6s;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  & > :not(:last-child) {
    margin-right: 4rem;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 1rem;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 2rem;
    }

    & > * {
      align-self: stretch;
    }
  }
`;

const PrimaryCallToAction = styled.a`
  font-size: 1rem;
  background-color: ${Theme.colors.secondary};
  padding: 0.8rem 1.7rem;
  border-radius: 1.6rem;
  color: ${Theme.colors.light};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-grow: 0;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${lighten(0.05, Theme.colors.secondary)};
    }
  }

  ${FadeInBottom}
  animation-delay: 1.25s;
  @media screen and (max-height: 700px) {
    animation-delay: 1s;
  }
`;

const SecondaryCallToAction = styled(PrimaryCallToAction)`
  background-color: transparent;
  border: 1px solid ${Theme.colors.light};

  &:hover {
    background-color: ${Theme.colors.light};
    color: ${Theme.colors.primary};
  }

  ${FadeInBottom}
  animation-delay: 1.5s;
  @media screen and (max-height: 700px) {
    animation-delay: 1.25s;
  }
`;

const ScrollDownIconLink = styled.a`
  position: absolute;
  cursor: pointer;
  bottom: 3rem;

  @media screen and (max-width: 450px) {
    bottom: 2rem;
  }

  ${FadeInBottom}
  animation-delay: 1.75s;
  @media screen and (max-height: 700px) {
    animation-delay: 1.5s;
  }
`;

const StyledScrollDownIcon = styled(IconScrollDown)`
  height: 1rem;
`;
