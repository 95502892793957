const Theme = {
  colors: {
    primary: "#293EA2",
    secondary: "#ba3660",
    tertiary: "#298BA2",
    dark: "#262B40",
    light: "#F0F4F8",
  },
};

export default Theme;
