function IconMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.578 21.169"
      {...props}
    >
      <g id="ios-mail" transform="translate(-48 -112)">
        <path
          d="M78.328,146.73l-7.909,8.056a.142.142,0,0,0,0,.206l5.535,5.9a.954.954,0,0,1,0,1.352.958.958,0,0,1-1.352,0l-5.513-5.873a.151.151,0,0,0-.213,0l-1.345,1.367a5.918,5.918,0,0,1-4.219,1.779A6.037,6.037,0,0,1,59,157.682l-1.294-1.316a.151.151,0,0,0-.213,0l-5.513,5.873a.958.958,0,0,1-1.352,0,.954.954,0,0,1,0-1.352l5.535-5.9a.157.157,0,0,0,0-.206L48.25,146.73a.145.145,0,0,0-.25.1v16.119a2.359,2.359,0,0,0,2.352,2.352H76.226a2.359,2.359,0,0,0,2.352-2.352V146.833A.147.147,0,0,0,78.328,146.73Z"
          transform="translate(0 -32.136)"
          fill="#f0f4f8"
        />
        <path
          d="M74.5,125.459a4,4,0,0,0,2.874-1.205l11.533-11.739A2.31,2.31,0,0,0,87.451,112h-25.9a2.3,2.3,0,0,0-1.455.515l11.533,11.739A4,4,0,0,0,74.5,125.459Z"
          transform="translate(-11.211)"
          fill="#f0f4f8"
        />
      </g>
    </svg>
  );
}

export default IconMail;
