function IconCall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.171 21.169"
      {...props}
    >
      <path
        id="ios-call"
        d="M84.416,80.611A17.782,17.782,0,0,0,80.7,78.125c-1.113-.535-1.521-.524-2.309.044-.656.474-1.08.915-1.835.75a10.948,10.948,0,0,1-3.687-2.728A10.869,10.869,0,0,1,70.142,72.5c-.16-.761.281-1.179.75-1.835.568-.788.584-1.2.044-2.309a17.43,17.43,0,0,0-2.486-3.715c-.81-.81-.992-.634-1.438-.474a8.186,8.186,0,0,0-1.317.7,3.973,3.973,0,0,0-1.582,1.67c-.314.678-.678,1.94,1.174,5.236a29.215,29.215,0,0,0,5.136,6.85l.006.006.006.006a29.328,29.328,0,0,0,6.85,5.136c3.3,1.852,4.558,1.488,5.236,1.174a3.906,3.906,0,0,0,1.67-1.582,8.186,8.186,0,0,0,.7-1.317C85.05,81.6,85.232,81.421,84.416,80.611Z"
        transform="translate(-63.857 -64.035)"
        fill="#f0f4f8"
      />
    </svg>
  );
}

export default IconCall;
