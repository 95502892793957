import IconGarage from "./svg/IconGarage";
import IconDoor from "./svg/IconDoor";
import styled from "styled-components";
import Theme from "../Theme";
import IconRemote from "./svg/IconRemote";

function Services() {
  return (
    <ServiceSection id="service-section">
      <Header>Unsere Leistungen</Header>
      <Container>
        <Service>
          <StyledGarageIcon />
          <ServiceHeader>Garagentore</ServiceHeader>
          <Description>
            Sie möchten Ihre Garage verschönern oder sich die Einfahrt bequemer
            gestalten? Das ist einfacher und günstiger als Sie denken. Nach
            einer Beratung liefern und montieren wir Ihr Wunschtor in der
            passenden Größe.
          </Description>
        </Service>
        <Service>
          <StyledRemoteIcon />
          <ServiceHeader>Antriebs- und Funktechnik</ServiceHeader>
          <Description>
            Das Öffnen und Schließen von Toren lässt sich automatisieren. Die
            automatische Hinderniserkennung bei Garagentorantrieben für den
            Privatbereich beispielsweise sorgt für optimalen Schutz.
          </Description>
        </Service>
        <Service>
          <StyledDoorIcon />
          <ServiceHeader>Türen</ServiceHeader>
          <Description>
            Sind Sie auf der Suche nach einer neuen Tür oder nach einer
            individuellen und auf Ihre Inneneinrichtung abgestimmte Designtür?
            Rufen Sie uns an, wir helfen Ihnen gerne weiter.
          </Description>
        </Service>
      </Container>
    </ServiceSection>
  );
}

export default Services;

const ServiceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${Theme.colors.primary};
  padding: 7rem 4rem 7rem 4rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 4rem;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 4rem;
    }
  }
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const Description = styled.div`
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5rem;
`;

const Header = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 6rem;
  text-align: center;
  @media screen and (max-width: 800px) {
    padding-bottom: 4rem;
  }
`;

const ServiceHeader = styled.h3`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bolder;
`;

const StyledGarageIcon = styled(IconGarage)`
  height: 6rem;
  display: block;
  margin: 0rem auto 2rem auto;
  fill: ${Theme.colors.primary};
`;

const StyledDoorIcon = styled(IconDoor)`
  height: 6rem;
  display: block;
  margin: 0rem auto 2rem auto;
  fill: ${Theme.colors.primary};
`;

const StyledRemoteIcon = styled(IconRemote)`
  height: 6rem;
  display: block;
  margin: 0rem auto 2rem auto;
  fill: ${Theme.colors.primary};
`;
