import { useState } from "react";
import styled from "styled-components";
import Theme from "../Theme";
import UserEvent from "../analytics/UserEvent";

function Contact() {
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  return (
    <ContactSection id="contact-section">
      <Header>Kontakt</Header>
      <Container>
        <Form>
          <label hidden={true} htmlFor="subject">
            Betreff
          </label>
          <Input
            id="subject"
            type="text"
            placeholder="Betreff"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <label hidden={true} htmlFor="subject">
            Anfrage
          </label>
          <Textarea
            placeholder="Ihre Anfrage..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ContactButton
            disabled={!subject || !message}
            onClick={() => {
              if (subject && message) {
                UserEvent("E-Mail", "E-Mail Anfrage über Kontaktformular");
                window.open(
                  `mailto:eugenhubert@gmx.net?subject=${subject}&body=${message}`,
                  "_blank"
                );
              }
            }}
          >
            Abschicken
          </ContactButton>
        </Form>
        <ContactDescription>
          <Info>
            Haben Sie noch Fragen? Nutzen Sie entweder unser Kontaktformular
            oder rufen Sie uns gleich an. Wir sind Montag bis Freitag zwischen
            08:00 Uhr und 17:00 Uhr für Sie erreichbar.
          </Info>
          <Address>
            <AddressLine>Eugen Hubert</AddressLine>
            <AddressLine>Behrnenweg 13</AddressLine>
            <AddressLine>47546 Kalkar</AddressLine>
          </Address>
          <LinkContainer>
            <Link
              href="tel:+4915750992638"
              onClick={() => UserEvent("Call", "Anruf über Kontaktformular")}
            >
              +49 157 50992638
            </Link>
            <Link
              href="mailto:eugenhubert@gmx.net"
              onClick={() =>
                UserEvent("E-Mail", "E-Mail Anfrage über Kontaktformular")
              }
            >
              eugenhubert@gmx.net
            </Link>
          </LinkContainer>
        </ContactDescription>
      </Container>
    </ContactSection>
  );
}

export default Contact;

const ContactSection = styled.div`
  background-color: ${Theme.colors.primary};
  color: ${Theme.colors.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 3rem 6rem 3rem;

  @media screen and (max-width: 450px) {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 3rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6rem;
  max-width: 800px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0rem;
    grid-row-gap: 2rem;
    grid-template-rows: 1fr 1fr;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Input = styled.input`
  font-size: 1rem;
  background-color: ${Theme.colors.light};
  border-radius: 3px;
  padding: 0.7rem 1rem;
`;

const Textarea = styled.textarea`
  font-size: 1rem;
  background-color: ${Theme.colors.light};
  border-radius: 3px;
  padding: 0.7rem 1rem;
  height: 10rem;
  resize: none;
`;

const ContactButton = styled.button`
  font-size: 1rem;
  text-align: center;
  background-color: ${Theme.colors.secondary};
  padding: 0.7rem 1.7rem;
  border-radius: 3px;
  color: ${Theme.colors.light};
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    opacity: 1;
    cursor: pointer;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: lighten(${Theme.colors.secondary}, 10%);
    }
  }
`;

const ContactDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5rem;
  flex-grow: 1;
`;

const Info = styled.div`
  margin-bottom: 2rem;
`;

const Address = styled.div`
  margin-bottom: 2rem;
`;

const AddressLine = styled.div``;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${Theme.colors.light};
`;
