import ReactGA from "react-ga4";

function UserEvent(action: string, label: string) {
  ReactGA.event({
    category: "User Action",
    action,
    label,
  });
}

export default UserEvent;
