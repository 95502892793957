function LogoSymbol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 59.4" {...props}>
      <g style={{ mixBlendMode: "soft-light", isolation: "isolate" }}>
        <rect
          width="12.54"
          height="12.38"
          transform="translate(33.84 33.82)"
          fill="#fff"
        />
        <path
          d="M56.95,26.65,30.3,0,14.87,15.43V10.06H5.69V24.61L0,30.3l1.15,1.15L30.3,2.31,56.28,28.28H100V26.65Z"
          fill="#fff"
        />
        <path
          d="M90.9,33.82h-27V57.77H23.81V33.82H12.55V57.77H3.58V59.4H100V57.77H90.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default LogoSymbol;
