import { keyframes, css } from "styled-components";

export const FadeInBottomKeyframes = keyframes`
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const FadeInBottom = css`
  animation: ${FadeInBottomKeyframes} 0.5s ease-out both;
`;
