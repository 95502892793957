import { useEffect } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Contact from "../components/Contact";
import Testimonials from "../components/Testimonials";
import CookieConsent from "../components/CookieConsent";

function Home() {
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "auto" });
  });

  return (
    <>
      <Header />
      <main>
        <Hero />
        <Services />
        <Gallery />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
      <CookieConsent />
    </>
  );
}

export default Home;
