import TestimonialFace1 from "./../assets/testimonial-face1.png";
import TestimonialFace2 from "./../assets/testimonial-face2.png";
import TestimonialFace3 from "./../assets/testimonial-face3.png";
import styled from "styled-components";
import { transparentize } from "polished";
import Theme from "../Theme";

function Testimonials() {
  return (
    <TestimonialSection>
      <Header>Das sagen unsere Kunden</Header>
      <SubHeader>Unser größter Erfolg sind zufriedene Auftraggeber</SubHeader>
      <Cards>
        <Card>
          <Image src={TestimonialFace1} alt="Paul Schneider" />
          <Name>Paul Schneider</Name>
          <Quote>
            Eugen ist ein zuverlässiger Handwerker. Vielen Dank für den Einbau
            unserer Tür!
          </Quote>
        </Card>
        <Card>
          <Image src={TestimonialFace2} alt="Alina Huck" />
          <Name>Alina Huck</Name>
          <Quote>
            Eugen baute uns ein Sektionaltor von Novoferm mit Antrieb ein. Wir
            sind sehr zufrieden mit dem Ergebnis!
          </Quote>
        </Card>
        <Card>
          <Image src={TestimonialFace3} alt="Marta Brasicz" />
          <Name>Marta Brasicz</Name>
          <Quote>
            Wir sind sehr zufrieden mit unserem Garagentor! Tolle Arbeit zu
            einem fairen Preis!
          </Quote>
        </Card>
      </Cards>
    </TestimonialSection>
  );
}

export default Testimonials;

const TestimonialSection = styled.div`
  padding: 12rem 2rem 2rem 2rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${Theme.colors.primary};
`;

const Header = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  padding-top: 8rem;
  padding-bottom: 1rem;
`;

const SubHeader = styled.h3`
  font-weight: 400;
  padding-bottom: 8rem;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 3rem;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 6rem;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 5rem 2rem 2rem 2rem;
  position: relative;
  box-shadow: 0 10px 32px 0 ${transparentize(0.91, Theme.colors.primary)};
  max-width: 300px;
`;

const Image = styled.img`
  position: absolute;
  width: 6rem;
  height: 6rem;
  top: -3rem;
  border-radius: 50%;
  box-shadow: 0 5px 8px 0 ${transparentize(0.8, Theme.colors.primary)};
  z-index: 2;
`;

const Name = styled.h4`
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 2rem;
`;

const Quote = styled.div`
  text-align: center;
  font-style: italic;
  line-height: 1.5rem;
`;
