function IconScroll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85.631 38.631"
      {...props}
    >
      <g transform="translate(-1028.684 -988.685)">
        <line
          x1="40"
          y1="33"
          transform="translate(1031.5 991.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          y1="33"
          x2="40"
          transform="translate(1071.5 991.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
}

export default IconScroll;
