function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 306.025 60"
      {...props}
    >
      <g transform="translate(120.974 45.25)">
        <path
          d="M167.11,68.09V82.6h-1.59V76H156.6v6.6H155V68.09h1.6v6.47h8.92V68.09ZM183.38,68,190,82.6h-1.66l-2.11-4.66h-6.64l-2.07,4.66h-1.66L182.31,68Zm2.19,8.54-2.73-6-2.64,6ZM212,68.09V82.6h-1.36l-10.37-12v12h-1.55V68.09h1.36l10.37,12v-12Zm10.41,0h4.49c4.66,0,8.69,2,8.69,7.25s-4,7.26-8.69,7.26h-4.49Zm1.59,1.4V81.2h3c3,0,6.87-.94,6.87-5.86S230,69.49,227,69.49Zm41.71-1.4L259.9,82.75h-.38l-4.81-12.07L249.9,82.75h-.41l-5.81-14.66h1.6l4.43,11,4.43-11h1.17l4.42,11,4.41-11Zm16.86,0v1.4h-6.5v5.09h6.31V76H276.1v5.2h6.67v1.4h-8.26V68.09Zm9.53,0h3.71c3.77,0,4.79,2.15,4.79,3.89a3.63,3.63,0,0,1-2.6,3.56c1,.68,1.77,1.81,3.56,4.62.61,1,.93,1.42,1.66,2.44H301.3L300,80.41c-.58-.91-1.22-1.83-1.88-2.68a3.72,3.72,0,0,0-3.3-1.77h-1V82.6h-1.6V68.09Zm3.47,1.4H293.7v5.07h1.87c2.07,0,3.32-.9,3.32-2.56s-1.07-2.51-3.29-2.51Zm26.27-1.4-6.6,7L323,82.6h-2.15l-7.51-7.23V82.6h-1.6V68.09h1.6V75l6.55-6.94Z"
          transform="translate(-155 -68)"
          fill="#f0f4f8"
        />
      </g>
      <g transform="translate(105.972 0.998)">
        <path
          d="M168.53,21a.73.73,0,0,1,.78.77V56.31a.73.73,0,0,1-.78.78h-5.2a.73.73,0,0,1-.78-.78V41.8H146.73V56.31a.73.73,0,0,1-.78.78h-5.18a.73.73,0,0,1-.77-.78V21.77a.73.73,0,0,1,.77-.77H146a.73.73,0,0,1,.78.77V35.82H162.6v-14a.73.73,0,0,1,.78-.77Zm37.84,0a.73.73,0,0,1,.78.77v20.7c0,10.44-6,15.36-15,15.36s-15-4.92-15-15.36V21.77a.73.73,0,0,1,.77-.77h5.18a.73.73,0,0,1,.78.77v20.7c0,5.8,3.19,8.92,8.25,8.92s8.24-3.12,8.24-8.92V21.77a.73.73,0,0,1,.78-.77Zm26.81,16.73c4.85,1,7.32,4.36,7.32,9,0,7.06-3.81,10.39-13,10.39H215.14a.73.73,0,0,1-.77-.78V21.77a.72.72,0,0,1,.77-.77H228c7.76,0,10.7,4.25,10.7,9.1C238.65,33.84,236.84,36.67,233.18,37.73Zm-.85-6.86c0-2.81-1.55-3.89-5-3.89h-6.24v8.33h6.27c3.48,0,4.97-1.45,4.97-4.44Zm-4.72,20.24c4.21,0,6.14-1.47,6.14-5,0-3.38-1.86-5.15-6.08-5.15h-6.58V51.11Zm43,0a.73.73,0,0,1,.78.77v4.43a.73.73,0,0,1-.78.78H248.24a.73.73,0,0,1-.78-.78V21.77a.73.73,0,0,1,.78-.77h22.29a.73.73,0,0,1,.78.77v4.44a.73.73,0,0,1-.78.77H254.19v8.64h15a.72.72,0,0,1,.77.77v4.46a.73.73,0,0,1-.77.77h-15v9.49Zm29.05-7.35,6.55,12.42c.28.55,0,.91-.59.91h-5.18a.91.91,0,0,1-.9-.6l-6.11-11.9H286V56.31a.73.73,0,0,1-.78.78H280a.73.73,0,0,1-.77-.78V21.77A.73.73,0,0,1,280,21h12.74c10.54,0,14,4.05,14,11.78-.04,5.72-1.9,9.41-7.11,10.98ZM286,27V38.61h7.27c4.4,0,7-1.09,7-5.83s-2.61-5.8-7-5.8Zm53.28-6a.73.73,0,0,1,.77.77v4.44a.73.73,0,0,1-.77.77h-10V56.31a.73.73,0,0,1-.78.78h-5.18a.73.73,0,0,1-.77-.78V27h-10a.73.73,0,0,1-.78-.77V21.77a.73.73,0,0,1,.78-.77Z"
          transform="translate(-139.998 -20.998)"
          fill="#f0f4f8"
        />
      </g>
      <g>
        <rect
          width="12.54"
          height="12.38"
          transform="translate(33.84 31.842)"
          fill="#f0f4f8"
        />
        <path
          d="M56.95,26.65,30.3,0,14.87,15.43V10.06H5.69V24.61L0,30.3l1.15,1.15L30.3,2.31,56.28,28.28H100V26.65Z"
          fill="#f0f4f8"
        />
        <path
          d="M90.9,33.82h-27V57.77H23.81V33.82H12.55V57.77H3.58V59.4H100V57.77H90.9Z"
          transform="translate(0 -2.75)"
          fill="#f0f4f8"
        />
      </g>
    </svg>
  );
}

export default Logo;
