function IconGarage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.501 159.501"
      {...props}
    >
      <defs />
      <g fill="none" stroke="#273aa5" strokeWidth="4">
        <circle cx="79.75" cy="79.75" r="79.75" stroke="none" />
        <circle cx="79.75" cy="79.75" r="77.75" />
      </g>
      <g>
        <g fill="#293ea2">
          <path d="M129.117 126.959h-9.986V30.982a2.084 2.084 0 00-2.084-2.084H41.886a2.084 2.084 0 00-2.084 2.084v89.367l-6.857 6.61h-2.587a2.084 2.084 0 100 4.168h98.759a2.085 2.085 0 100-4.17zm-68.2 0H38.953l20.964-20.214a2.087 2.087 0 00.573-2.015 26.451 26.451 0 01-.617-3.723c-.224-2.59.213-3.6.383-3.753 1.77-1.561 7.6-4.941 9.7-6.114a2 2 0 00.17-.106c.857-.594 3.177-1.69 3.83-.882.538.666.475 1.052.451 1.2a3.72 3.72 0 01-1.539 1.917l-7.109 3.888a2.085 2.085 0 00-1.077 1.98c0 .049-.006.1-.006.148v10.297a2.084 2.084 0 002.084 2.084h17.662a73.415 73.415 0 01-6.063 5.65c-3.033 2.463-4.869 1.273-5.159 1.054a1.987 1.987 0 00-2.762.018l-9.272 8.306a2.078 2.078 0 00-.249.265zm18.55-25.209a6.216 6.216 0 10-1.19-12.313 6.441 6.441 0 00-1.086-1.9c-.834-1.031-2.771-2.587-6.379-1.423a12.379 12.379 0 00-1.97.844v-3.3H90.09v23.84H68.842v-7.277l4.765-2.608a6.229 6.229 0 005.86 4.137zm-2.05-6.213a2.047 2.047 0 112.047 2.047 2.049 2.049 0 01-2.047-2.047zm37.543 31.425H67.117l4.991-4.47a7.132 7.132 0 001.545.434 7.282 7.282 0 001.189.1 10.032 10.032 0 006.145-2.468 90.641 90.641 0 009.153-8.887h2.038a2.084 2.084 0 002.084-2.084V81.571a2.084 2.084 0 00-2.084-2.084h-9.171a4.133 4.133 0 00-7.081 0h-9.168a2.084 2.084 0 00-2.084 2.084v7.406a2.063 2.063 0 00.033.37 59 59 0 00-7.21 4.778c-1.509 1.333-2.09 3.718-1.773 7.294a29.9 29.9 0 00.458 3.133L43.971 116.33V55.222h70.992v71.736zm0-75.9H43.971v-6.91h70.992v6.91zm0-11.078H43.971v-6.916h70.992v6.908z" />
          <path d="M68.89 69.909a2.106 2.106 0 001.111-.322l-.009.008.021-.017a2.028 2.028 0 00.374-.294 13.713 13.713 0 012.164-1.3 15.988 15.988 0 0113.828 0 13.619 13.619 0 012.135 1.276 2.137 2.137 0 00.422.331 2.1 2.1 0 002.776-3.067.633.633 0 00-.124-.151 2.1 2.1 0 00-.542-.42 19.949 19.949 0 00-23.129-.024 2.111 2.111 0 00-.612.481l-.109.1.016.017a2.088 2.088 0 00-.435 1.272 2.113 2.113 0 002.113 2.11z" />
          <path d="M88.14 72.215a14.589 14.589 0 00-8.674-2.812 14.3 14.3 0 00-9.139 3.187 2.084 2.084 0 102.805 3.083 10.2 10.2 0 016.332-2.1 10.076 10.076 0 016.345 2.113l-.013-.012a2.106 2.106 0 102.34-3.453z" />
        </g>
      </g>
    </svg>
  );
}

export default IconGarage;
