import { useState } from "react";
import Logo from "./svg/Logo";
import IconCall from "./svg/IconCall";
import IconMail from "./svg/IconMail";
import styled, { css } from "styled-components";
import Theme from "../Theme";
import { Navigate } from "react-router-dom";
import UserEvent from "../analytics/UserEvent";
import IconInstagram from "./svg/IconInstagram";

function Header() {
  const [toHome, setToHome] = useState<boolean>(false);

  return (
    <Container>
      <StyledLogo onClick={() => setToHome(true)} />
      {toHome && <Navigate to="/" />}
      <Nav>
        <NavLink
          href="tel:+4915750992638"
          onClick={() => UserEvent("Call", "Anruf über Navigationsleiste")}
        >
          <StyledIconCall />
          <NavLinkText>+49 157 50992638</NavLinkText>
        </NavLink>
        <NavLink
          href="mailto:eugenhubert@gmx.net"
          onClick={() =>
            UserEvent("E-Mail", "E-Mail Anfrage über Navigationsleiste")
          }
        >
          <StyledIconMail />
          <NavLinkText>eugenhubert@gmx.net</NavLinkText>
        </NavLink>
        <NavLinkInstagram
          href="https://www.instagram.com/huberthandwerk/"
          onClick={() =>
            UserEvent("Instagram", "Instagram Anfrage über Navigationsleiste")
          }
        >
          <StyledIconInstagram />
          <NavLinkText>Instagram</NavLinkText>
        </NavLinkInstagram>
        <NavButton
          href="/#contact-section"
          onClick={() =>
            UserEvent(
              "Contact",
              "Kontaktformular aufgerufen über Navigationsleiste"
            )
          }
        >
          Kontakt
        </NavButton>
      </Nav>
    </Container>
  );
}

export default Header;

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  color: ${Theme.colors.light};
  background-color: ${Theme.colors.primary};
  opacity: 0.98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: $color-light;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  z-index: 5;

  @media screen and (max-width: 450px) {
    padding: 1rem 1rem;
  }
`;

const StyledLogo = styled(Logo)`
  height: 2rem;
  flex-shrink: 0;
  padding-right: 2rem;
  cursor: pointer;
`;

const IconCss = css`
  height: 1rem;
  padding-right: 0.7rem;
  flex-shrink: 0;
`;

const StyledIconCall = styled(IconCall)`
  ${IconCss}
`;

const StyledIconMail = styled(IconMail)`
  ${IconCss}
`;

const StyledIconInstagram = styled(IconInstagram)`
  ${IconCss}
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;
  color: ${Theme.colors.light};
  text-decoration: none;
  transition: border-bottom 0.3s ease-in;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

const NavLinkInstagram = styled(NavLink)`
  @media screen and (max-width: 550px) {
    display: flex;
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

const NavLinkText = styled.span`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -0.3rem;
    left: 0;
    background-color: ${Theme.colors.light};
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const NavButton = styled.a`
  font-size: 1rem;
  position: relative;
  color: ${Theme.colors.light};
  text-decoration: none;
  border: 1px solid ${Theme.colors.light};
  padding: 0.4rem 1.7rem;
  border-radius: 1.4rem;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${Theme.colors.light};
      color: ${Theme.colors.primary};
    }
  }

  @media screen and (max-width: 350px) {
    display: none;
  }
`;
